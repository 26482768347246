export const WarehouseV2Lock = {
  name: 'WarehouseV2Lock',
  attrs: {
    backupId: {
      name: 'backupId',
      id: 'WarehouseV2Lock.backupId',
      type: {
        name: 'integer'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    backupLockNameI18Code: {
      name: 'backupLockNameI18Code',
      id: 'WarehouseV2Lock.backupLockNameI18Code',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    endHour: {
      name: 'endHour',
      id: 'WarehouseV2Lock.endHour',
      type: {
        name: 'integer'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    id: {
      name: 'id',
      id: 'WarehouseV2Lock.id',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    lockNameI18Code: {
      name: 'lockNameI18Code',
      id: 'WarehouseV2Lock.lockNameI18Code',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    startHour: {
      name: 'startHour',
      id: 'WarehouseV2Lock.startHour',
      type: {
        name: 'integer'
      },
      required: false,
      validationRules: [],
      extra: {}
    }
  }
}
