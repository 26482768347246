import React, { ComponentType } from 'react'
import { Text } from 'src/ui/basic/Text'
import { ctxQuery } from 'src/fetch/useContextFetch'
import { css } from 'src/ui/style/css'
import { getAppContext } from '../context/AppContext'

export const Version: ComponentType = () => {
    const version = getAppContext()?.config?.appVersion
    const apiVersion = ctxQuery(ctx => ctx.version.getApiVersion)()
    return (
        <Text
            css={css`
                font-size: 12px;
                white-space: nowrap;
            `}
        >
            {(version ? 'Version: ' + version : '') +
                (apiVersion ? ` (api: ${apiVersion})` : '')}
        </Text>
    )
}
