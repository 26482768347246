import { useUser } from 'src/auth/useUser'
import { msg } from 'src/i18n/texts/keys'
import { pages } from 'src/routing/pages'
import { PageRouteDef } from '@ps-aux/react-app-core'
import { UserRole } from 'src/auth/User'

export type PageNavItem = [string, PageRouteDef<any>]
const ownerItems: PageNavItem[] = [
    [msg.Dashboard.title, pages.Dashboard.Dashboard],
    [msg.AdminWarehouse.entity.plural, pages.Warehouse.List],
    [msg.AdminRent.entity.plural, pages.Rent.List],
    [msg.RysAuditListItem.entity.plural, pages.RysAudit.List]
]

const adminItems: PageNavItem[] = [
    ...ownerItems,
    [msg.ClientUser.entity.plural, pages.User.ClientAppUserList],
    [msg.AppUser.admin.plural, pages.User.AdminAppUserList],
    [msg.AppUser.owner.plural, pages.User.OwnerAppUserList],
    [msg.Owner.entity.plural, pages.Owner.List],
    [msg.FranchiseContact.entity.plural, pages.FranchiseContact.List],
    [msg.ServiceAssess.entity.plural, pages.ServiceAccess.List]
]

const superadminItems: PageNavItem[] = [
    ['Superadmin dashboard', pages.Superadmin.Root],
    ...adminItems
]

const maintenanceItems: PageNavItem[] = [
    [msg.AdminWarehouse.entity.plural, pages.Warehouse.List]
]

export const useNavigationItems = (): PageNavItem[] => {
    const user = useUser()
    if (!user) return []
    const { role } = user

    console.log('role', role)

    switch (role) {
        case UserRole.Admin:
            return adminItems
        case UserRole.OwnerReadOnly:
            return ownerItems
        case UserRole.OwnerReadWrite:
            return ownerItems
        case UserRole.Superadmin:
            return superadminItems
        case UserRole.Maintenance:
            return maintenanceItems
    }

    throw new Error('Unexpected role ' + role)
}
