import { Money } from 'src/api/defs/Money.type'
import { formatLocale } from 'src/i18n/formatSettings'
import { LanguageFormat } from 'src/types/LanguageFormat'
import { NumberFormatOptions } from '@formatjs/ecma402-abstract'

export const strMoney = (
    m: Money,
    format?: LanguageFormat,
    hideCurrency?: boolean
): string => {
    const getFormatLocale = (format?: string) => {
        switch (format) {
            case LanguageFormat.CS:
                return 'CZK'
            case LanguageFormat.EN:
                return 'EUR'
            case LanguageFormat.SK:
                return 'EUR'
            case LanguageFormat.HU:
                return 'HUF'
            default:
                return 'EUR'
        }
    }

    console.log('formatLocale: ', formatLocale)

    const options: NumberFormatOptions = {
        minimumFractionDigits: formatLocale === LanguageFormat.HU ? 0 : 2,
        maximumFractionDigits: formatLocale === LanguageFormat.HU ? 0 : 2,
        style: 'currency',
        currency: getFormatLocale(formatLocale)
    }

    if (hideCurrency) {
        options.style = 'decimal'
        options.currency = undefined
    }

    const f = new Intl.NumberFormat(format ?? formatLocale, options)

    return f.format(m.value / 100)
}
