export const MyContract = {
  name: 'MyContract',
  attrs: {
    backupLockNameI18Code: {
      name: 'backupLockNameI18Code',
      id: 'MyContract.backupLockNameI18Code',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    boxDimensions: {
      name: 'boxDimensions',
      id: 'MyContract.boxDimensions',
      type: {
        name: 'object',
        of: 'BoxDimensions'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    boxId: {
      name: 'boxId',
      id: 'MyContract.boxId',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    boxIsShared: {
      name: 'boxIsShared',
      id: 'MyContract.boxIsShared',
      type: {
        name: 'boolean'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    boxLockInfo: {
      name: 'boxLockInfo',
      id: 'MyContract.boxLockInfo',
      type: {
        name: 'object',
        of: 'BoxLockInfo'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    boxName: {
      name: 'boxName',
      id: 'MyContract.boxName',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    boxSharedFromContract: {
      name: 'boxSharedFromContract',
      id: 'MyContract.boxSharedFromContract',
      type: {
        name: 'integer'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    boxSharingClients: {
      name: 'boxSharingClients',
      id: 'MyContract.boxSharingClients',
      type: {
        name: 'list',
        of: {
          name: 'object',
          of: 'ClientUser'
        }
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    businessId: {
      name: 'businessId',
      id: 'MyContract.businessId',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    camera: {
      name: 'camera',
      id: 'MyContract.camera',
      type: {
        name: 'object',
        of: 'BoxCamera'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    cameraChannelNo: {
      name: 'cameraChannelNo',
      id: 'MyContract.cameraChannelNo',
      type: {
        name: 'integer'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    cameraDeviceSerial: {
      name: 'cameraDeviceSerial',
      id: 'MyContract.cameraDeviceSerial',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    createdAt: {
      name: 'createdAt',
      id: 'MyContract.createdAt',
      type: {
        name: 'date'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    day: {
      name: 'day',
      id: 'MyContract.day',
      type: {
        name: 'object',
        of: 'Day'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    deductionReason: {
      name: 'deductionReason',
      id: 'MyContract.deductionReason',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    depositDeductionAmount: {
      name: 'depositDeductionAmount',
      id: 'MyContract.depositDeductionAmount',
      type: {
        name: 'object',
        of: 'Money'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    depositPrice: {
      name: 'depositPrice',
      id: 'MyContract.depositPrice',
      type: {
        name: 'object',
        of: 'VatPrice'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    hasEvictedAndCleanedCancellationRequest: {
      name: 'hasEvictedAndCleanedCancellationRequest',
      id: 'MyContract.hasEvictedAndCleanedCancellationRequest',
      type: {
        name: 'boolean'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    hasUnsolvedCancellationRequest: {
      name: 'hasUnsolvedCancellationRequest',
      id: 'MyContract.hasUnsolvedCancellationRequest',
      type: {
        name: 'boolean'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    id: {
      name: 'id',
      id: 'MyContract.id',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    insurance: {
      name: 'insurance',
      id: 'MyContract.insurance',
      type: {
        name: 'object',
        of: 'BoxInsurance'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    invoicingType: {
      name: 'invoicingType',
      id: 'MyContract.invoicingType',
      type: {
        name: 'object',
        of: 'ContractInvoicingType'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    lockNameI18Code: {
      name: 'lockNameI18Code',
      id: 'MyContract.lockNameI18Code',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    note: {
      name: 'note',
      id: 'MyContract.note',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    overpayment: {
      name: 'overpayment',
      id: 'MyContract.overpayment',
      type: {
        name: 'object',
        of: 'VatPrice'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    reasons: {
      name: 'reasons',
      id: 'MyContract.reasons',
      type: {
        name: 'list',
        of: {
          name: 'string'
        }
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    refundStatus: {
      name: 'refundStatus',
      id: 'MyContract.refundStatus',
      type: {
        name: 'enum',
        id: 'RefundStatus',
        of: ['CREATED', 'AUTO_SUCCESS', 'AUTO_FAIL']
      },
      required: false,
      validationRules: [],
      extra: {
        enumId: 'RefundStatus'
      }
    },
    status: {
      name: 'status',
      id: 'MyContract.status',
      type: {
        name: 'enum',
        id: 'Contract$Status',
        of: ['ACTIVE', 'TERMINATING', 'EVICTED', 'TERMINATED']
      },
      required: true,
      validationRules: [],
      extra: {
        enumId: 'Contract$Status'
      }
    },
    terminationAt: {
      name: 'terminationAt',
      id: 'MyContract.terminationAt',
      type: {
        name: 'date'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    totalPrice: {
      name: 'totalPrice',
      id: 'MyContract.totalPrice',
      type: {
        name: 'object',
        of: 'VatPrice'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    voucherNumberOfPayments: {
      name: 'voucherNumberOfPayments',
      id: 'MyContract.voucherNumberOfPayments',
      type: {
        name: 'integer'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    voucherRate: {
      name: 'voucherRate',
      id: 'MyContract.voucherRate',
      type: {
        name: 'double'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    voucherToken: {
      name: 'voucherToken',
      id: 'MyContract.voucherToken',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    voucherValidFrom: {
      name: 'voucherValidFrom',
      id: 'MyContract.voucherValidFrom',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    voucherValidTo: {
      name: 'voucherValidTo',
      id: 'MyContract.voucherValidTo',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    warehouse: {
      name: 'warehouse',
      id: 'MyContract.warehouse',
      type: {
        name: 'object',
        of: 'WarehouseInfo'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    warehouseLockEndHour: {
      name: 'warehouseLockEndHour',
      id: 'MyContract.warehouseLockEndHour',
      type: {
        name: 'integer'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    warehouseLockStartHour: {
      name: 'warehouseLockStartHour',
      id: 'MyContract.warehouseLockStartHour',
      type: {
        name: 'integer'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    warehouseLockVersion: {
      name: 'warehouseLockVersion',
      id: 'MyContract.warehouseLockVersion',
      type: {
        name: 'enum',
        id: 'LockVersion',
        of: ['V1', 'V2']
      },
      required: true,
      validationRules: [],
      extra: {
        enumId: 'LockVersion'
      }
    },
    withCamera: {
      name: 'withCamera',
      id: 'MyContract.withCamera',
      type: {
        name: 'boolean'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    withDeposit: {
      name: 'withDeposit',
      id: 'MyContract.withDeposit',
      type: {
        name: 'boolean'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    withVoucherDiscount: {
      name: 'withVoucherDiscount',
      id: 'MyContract.withVoucherDiscount',
      type: {
        name: 'boolean'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
}
