import { AxiosInstance } from 'axios'
import { User, UserRole } from 'src/auth/User'
import { AppError } from 'src/error/AppError'

export type LoginRequest = {
    username: string
    password: string
}

export const isInvalidCredentials = (err: AppError): boolean =>
    err.code === 'invalid-credentials'

export class AuthApi {
    constructor(private http: AxiosInstance) {}

    // TODO implement
    logIn = (req: LoginRequest): Promise<User> =>
        this.http
            .post('/auth/custom/login', {
                username: req.username,
                password: req.password,
                domain: 'ADMIN'
            })
            .then(({ data: { token, info } }) => {
                const superadmin = info.superadmin === 'true'
                const ownerReadOnly = info['owner/read-only'] === 'true'
                const ownerReadWrite = info['owner/read-write'] === 'true'
                const maintenance = info.utilityman === 'true'

                let role: UserRole = UserRole.Admin
                if (ownerReadOnly) role = UserRole.OwnerReadOnly
                else if (superadmin) role = UserRole.Superadmin
                else if (ownerReadWrite) role = UserRole.OwnerReadWrite
                else if (maintenance) role = UserRole.Maintenance
                return {
                    token: token,
                    username: info.username,
                    admin: !ownerReadOnly,
                    superadmin,
                    owner: ownerReadOnly || ownerReadWrite,
                    role
                }
            })
}
