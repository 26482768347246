import { Theme } from 'src/ui/style/theme/Theme'
// @ts-ignore
import sassTheme from './theme.scss'
import { ScreenSize } from 'src/ui/style/ScreenSize'

const fromSass = (key: string): string => {
    const res = sassTheme[key]

    if (!res) throw new Error(`Variable ${key} not defined in the sass theme`)
    return res
}

const primaryColor = fromSass('color-primary')

export const appTheme = (): Theme => ({
    colors: {
        primary: primaryColor,
        secondary: fromSass('color-secondary'),
        tertiary: '#00B2EE',
        white: '#ffffff',
        black: '#000000',
        error: '#901d1d',
        lightGray: '#dddddd',
        darkGray: '#555555',
        gray: '#aaaaaa',
        outline: primaryColor,
        hover: '#dddddd'
    },
    fonts: {
        primary: 'Raleway, sans-serif'
    },
    fontColor: {
        primary: fromSass('color-font-primary')
    },
    fontSize: {
        xl: '1.6em',
        lg: '1.3em',
        md: '1.2em',
        sm: '0.85em',
        xs: '0.75em'
    },
    fontWeight: {
        thin: 300,
        normal: 400,
        bold: 700
    },
    spacing: {
        sm: '0.5rem',
        md: '1rem',
        lg: '2rem'
    },
    screens: {
        md: `${ScreenSize.sizes.md}px`
    },
    borderRadius: {
        primary: fromSass('borderRadius-primary')
    },
    boxShadow: {
        bottomOnly: '0px 8px 10px -8px rgba(0, 0, 0, 0.4)'
    },
    iconToTextSizeRatio: '140%',
    outline: `1px auto ${primaryColor}`,
    containerMaxWidth: '1140px',
    border: {
        primary: `1px solid ${primaryColor}`
    },
    input: {
        height: fromSass('inputHeight'),
        padding: fromSass('inputPadding'),
        border: fromSass('inputBorder'),
        borderWidth: fromSass('inputBorderWidth'),
        borderColor: fromSass('inputBorderColor'),
        focus: {
            borderColor: fromSass('inputFocusBorderColor'),
            boxShadow: fromSass('inputFocusShadow')
        },
        fontSize: fromSass('inputFontSize')
    }
})
