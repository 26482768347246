import { apiOps } from 'src/api/defs/apiOps'
import { HigherApiClient } from 'src/api/client/ApiClient'
import { AdminContract } from 'src/api/defs/AdminContract.type'
import { ListReq } from 'src/model/api/ListReq'
import { Page } from 'src/model/api/Page'
import { AdminRent } from 'src/api/defs/AdminRent.type'
import { RentListFilter } from 'src/business/Rent/RentApi'
import { ContractInvoicingType } from 'src/api/defs/ContractInvoicingType.type'
import { LockPin } from 'src/api/defs/LockPin.type'
import { DownloadLink } from 'src/api/defs/DownloadLink.type'
import { m } from 'src/api/model/model'
import { CancelContractCmd } from 'src/api/defs/CancelContractCmd.type'
import { DeductionFromDepositCommand } from '../../api/defs/DeductionFromDepositCommand.type'
import { Money } from '../../api/defs/Money.type'

const adminOps = apiOps.AdminContract
const ownerOps = apiOps.OwnerContract

export class ContractApi {
    constructor(private api: HigherApiClient, private isOwner: () => boolean) {}

    detail = (id: string): Promise<AdminContract> =>
        this.api.doForId<AdminContract>(
            this.isOwner() ? ownerOps.contract : adminOps.readDetail,
            m.AdminContract,
            id
        )

    rents = (
        id: string,
        req: ListReq<RentListFilter>
    ): Promise<Page<AdminRent>> =>
        this.api.getPage<AdminRent, any>(
            this.isOwner() ? ownerOps.rents : adminOps.rents,
            m.AdminRent,
            req,
            {
                id
            }
        )

    refund = (id: string): Promise<any> =>
        this.api.doForId<void>(adminOps.refund, null, id, {})

    // TODO maybe a separate API ?
    allInvoicingTypes = (): Promise<ContractInvoicingType[]> =>
        this.api.do(apiOps.CommonAdminContract.invoicingTypes)

    getAccessPin = (contractId: string): Promise<string> =>
        this.api
            .doForId<LockPin>(
                this.isOwner()
                    ? ownerOps.getLockAccessPin
                    : adminOps.getLockAccessPin,
                null,
                contractId
            )
            .then(res => res.value)

    downloadLegalAgreement = (id: string): Promise<DownloadLink> =>
        this.api.doForId(
            apiOps.CommonAdminContract.legalAgreementDownload,
            null,
            id
        )

    cancelContract = (id: string, req: CancelContractCmd): Promise<any> =>
        this.api
            .doForId<void, CancelContractCmd>(
                this.isOwner() ? ownerOps.cancel : adminOps.cancel,
                null,
                id,
                req
            )
            .then(() => {
                window.location.reload()
            })

    confirmEvict = (id: string): Promise<any> =>
        this.api.doForId<void>(
            this.isOwner() ? ownerOps.evict : adminOps.evict,
            null,
            id
        )

    setDeductionFromDeposit = (
        id: string,
        deductionAmount: Money,
        deductionReason: string
    ): Promise<any> =>
        this.api.doForId<void, DeductionFromDepositCommand>(
            adminOps.setDeductionFromDeposit,
            null,
            id,
            {
                deductionAmount,
                deductionReason
            }
        )
}
