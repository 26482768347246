import { commonPrefix } from 'src/multirole/commonPrefix'
import { maintenancePrefix } from '../Warehouse/WarehousePages'

export const BoxPages = {
    DetailBox: {
        path: `${commonPrefix}/box/:id`,
        comp: () => import('src/business/Box/BoxDetailPage'),
        to: (id: string): string => `${commonPrefix}/box/${id}`
    },
    MaintenanceDetailBox: {
        path: `${maintenancePrefix}/maintenance/box/:id`,
        comp: () => import('src/business/Box/BoxDetailMaintenancePage'),
        to: (id: string): string => `${maintenancePrefix}/maintenance/box/${id}`
    }
}
