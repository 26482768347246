export const AdminRentPaying = {
  name: 'AdminRentPaying',
  attrs: {
    adminPaymentNote: {
      name: 'adminPaymentNote',
      id: 'AdminRentPaying.adminPaymentNote',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    adminPaymentType: {
      name: 'adminPaymentType',
      id: 'AdminRentPaying.adminPaymentType',
      type: {
        name: 'enum',
        id: 'RentPaying$AdminPaymentType',
        of: ['CANCELED', 'INCLUSION', 'BANK_TRANSFER', 'DEPOSIT']
      },
      required: false,
      validationRules: [],
      extra: {
        enumId: 'RentPaying$AdminPaymentType'
      }
    },
    payMethod: {
      name: 'payMethod',
      id: 'AdminRentPaying.payMethod',
      type: {
        name: 'enum',
        id: 'RentPaying$PayMethod',
        of: ['AUTOPAY', 'MANUAL', 'FROM_ORDER', 'MARKED_BY_ADMIN']
      },
      required: false,
      validationRules: [],
      extra: {
        enumId: 'RentPaying$PayMethod'
      }
    },
    status: {
      name: 'status',
      id: 'AdminRentPaying.status',
      type: {
        name: 'enum',
        id: 'RentPaying$Status',
        of: ['CREATED', 'ATTEMPTING_AUTOPAY', 'WAITING_FOR_MANUAL', 'PAID']
      },
      required: true,
      validationRules: [],
      extra: {
        enumId: 'RentPaying$Status'
      }
    }
  }
};
