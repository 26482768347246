export const MarkPaidCmd = {
    name: 'MarkPaidCmd',
    attrs: {
        adminPaymentNote: {
            name: 'adminPaymentNote',
            id: 'MarkPaidCmd.adminPaymentNote',
            type: {
                name: 'string'
            },
            required: false,
            validationRules: [],
            extra: {}
        },
        adminPaymentType: {
            name: 'adminPaymentType',
            id: 'MarkPaidCmd.adminPaymentType',
            type: {
                name: 'enum',
                id: 'RentPaying$AdminPaymentType',
                of: ['CANCELED', 'INCLUSION', 'BANK_TRANSFER', 'DEPOSIT']
            },
            required: false,
            validationRules: [],
            extra: {
                enumId: 'RentPaying$AdminPaymentType'
            }
        },
        paidAt: {
            name: 'paidAt',
            id: 'MarkPaidCmd.paidAt',
            type: {
                name: 'string'
            },
            required: false,
            validationRules: [],
            extra: {}
        }
    }
}
