export enum CountryCode {
  SVK = 'SVK',
  CZE = 'CZE',
  ABW = 'ABW',
  AFG = 'AFG',
  AGO = 'AGO',
  AIA = 'AIA',
  ALA = 'ALA',
  ALB = 'ALB',
  AND = 'AND',
  ARE = 'ARE',
  ARG = 'ARG',
  ARM = 'ARM',
  ASM = 'ASM',
  ATA = 'ATA',
  ATF = 'ATF',
  ATG = 'ATG',
  AUS = 'AUS',
  AUT = 'AUT',
  AZE = 'AZE',
  BDI = 'BDI',
  BEL = 'BEL',
  BEN = 'BEN',
  BES = 'BES',
  BFA = 'BFA',
  BGD = 'BGD',
  BGR = 'BGR',
  BHR = 'BHR',
  BHS = 'BHS',
  BIH = 'BIH',
  BLM = 'BLM',
  BLR = 'BLR',
  BLZ = 'BLZ',
  BMU = 'BMU',
  BOL = 'BOL',
  BRA = 'BRA',
  BRB = 'BRB',
  BRN = 'BRN',
  BTN = 'BTN',
  BVT = 'BVT',
  BWA = 'BWA',
  CAF = 'CAF',
  CAN = 'CAN',
  CCK = 'CCK',
  CHE = 'CHE',
  CHL = 'CHL',
  CHN = 'CHN',
  CIV = 'CIV',
  CMR = 'CMR',
  COD = 'COD',
  COG = 'COG',
  COK = 'COK',
  COL = 'COL',
  COM = 'COM',
  CPV = 'CPV',
  CRI = 'CRI',
  CUB = 'CUB',
  CUW = 'CUW',
  CXR = 'CXR',
  CYM = 'CYM',
  CYP = 'CYP',
  DEU = 'DEU',
  DJI = 'DJI',
  DMA = 'DMA',
  DNK = 'DNK',
  DOM = 'DOM',
  DZA = 'DZA',
  ECU = 'ECU',
  EGY = 'EGY',
  ERI = 'ERI',
  ESH = 'ESH',
  ESP = 'ESP',
  EST = 'EST',
  ETH = 'ETH',
  FIN = 'FIN',
  FJI = 'FJI',
  FLK = 'FLK',
  FRA = 'FRA',
  FRO = 'FRO',
  FSM = 'FSM',
  GAB = 'GAB',
  GBR = 'GBR',
  GEO = 'GEO',
  GGY = 'GGY',
  GHA = 'GHA',
  GIB = 'GIB',
  GIN = 'GIN',
  GLP = 'GLP',
  GMB = 'GMB',
  GNB = 'GNB',
  GNQ = 'GNQ',
  GRC = 'GRC',
  GRD = 'GRD',
  GRL = 'GRL',
  GTM = 'GTM',
  GUF = 'GUF',
  GUM = 'GUM',
  GUY = 'GUY',
  HKG = 'HKG',
  HMD = 'HMD',
  HND = 'HND',
  HRV = 'HRV',
  HTI = 'HTI',
  HUN = 'HUN',
  IDN = 'IDN',
  IMN = 'IMN',
  IND = 'IND',
  IOT = 'IOT',
  IRL = 'IRL',
  IRN = 'IRN',
  IRQ = 'IRQ',
  ISL = 'ISL',
  ISR = 'ISR',
  ITA = 'ITA',
  JAM = 'JAM',
  JEY = 'JEY',
  JOR = 'JOR',
  JPN = 'JPN',
  KAZ = 'KAZ',
  KEN = 'KEN',
  KGZ = 'KGZ',
  KHM = 'KHM',
  KIR = 'KIR',
  KNA = 'KNA',
  KOR = 'KOR',
  KWT = 'KWT',
  LAO = 'LAO',
  LBN = 'LBN',
  LBR = 'LBR',
  LBY = 'LBY',
  LCA = 'LCA',
  LIE = 'LIE',
  LKA = 'LKA',
  LSO = 'LSO',
  LTU = 'LTU',
  LUX = 'LUX',
  LVA = 'LVA',
  MAC = 'MAC',
  MAF = 'MAF',
  MAR = 'MAR',
  MCO = 'MCO',
  MDA = 'MDA',
  MDG = 'MDG',
  MDV = 'MDV',
  MEX = 'MEX',
  MHL = 'MHL',
  MKD = 'MKD',
  MLI = 'MLI',
  MLT = 'MLT',
  MMR = 'MMR',
  MNE = 'MNE',
  MNG = 'MNG',
  MNP = 'MNP',
  MOZ = 'MOZ',
  MRT = 'MRT',
  MSR = 'MSR',
  MTQ = 'MTQ',
  MUS = 'MUS',
  MWI = 'MWI',
  MYS = 'MYS',
  MYT = 'MYT',
  NAM = 'NAM',
  NCL = 'NCL',
  NER = 'NER',
  NFK = 'NFK',
  NGA = 'NGA',
  NIC = 'NIC',
  NIU = 'NIU',
  NLD = 'NLD',
  NOR = 'NOR',
  NPL = 'NPL',
  NRU = 'NRU',
  NZL = 'NZL',
  OMN = 'OMN',
  PAK = 'PAK',
  PAN = 'PAN',
  PCN = 'PCN',
  PER = 'PER',
  PHL = 'PHL',
  PLW = 'PLW',
  PNG = 'PNG',
  POL = 'POL',
  PRI = 'PRI',
  PRK = 'PRK',
  PRT = 'PRT',
  PRY = 'PRY',
  PSE = 'PSE',
  PYF = 'PYF',
  QAT = 'QAT',
  REU = 'REU',
  ROU = 'ROU',
  RUS = 'RUS',
  RWA = 'RWA',
  SAU = 'SAU',
  SDN = 'SDN',
  SEN = 'SEN',
  SGP = 'SGP',
  SGS = 'SGS',
  SHN = 'SHN',
  SJM = 'SJM',
  SLB = 'SLB',
  SLE = 'SLE',
  SLV = 'SLV',
  SMR = 'SMR',
  SOM = 'SOM',
  SPM = 'SPM',
  SRB = 'SRB',
  SSD = 'SSD',
  STP = 'STP',
  SUR = 'SUR',
  SVN = 'SVN',
  SWE = 'SWE',
  SWZ = 'SWZ',
  SXM = 'SXM',
  SYC = 'SYC',
  SYR = 'SYR',
  TCA = 'TCA',
  TCD = 'TCD',
  TGO = 'TGO',
  THA = 'THA',
  TJK = 'TJK',
  TKL = 'TKL',
  TKM = 'TKM',
  TLS = 'TLS',
  TON = 'TON',
  TTO = 'TTO',
  TUN = 'TUN',
  TUR = 'TUR',
  TUV = 'TUV',
  TWN = 'TWN',
  TZA = 'TZA',
  UGA = 'UGA',
  UKR = 'UKR',
  UMI = 'UMI',
  URY = 'URY',
  USA = 'USA',
  UZB = 'UZB',
  VAT = 'VAT',
  VCT = 'VCT',
  VEN = 'VEN',
  VGB = 'VGB',
  VIR = 'VIR',
  VNM = 'VNM',
  VUT = 'VUT',
  WLF = 'WLF',
  WSM = 'WSM',
  YEM = 'YEM',
  ZAF = 'ZAF',
  ZMB = 'ZMB',
  ZWE = 'ZWE'
}

export enum Contract$Status {
  ACTIVE = 'ACTIVE',
  TERMINATING = 'TERMINATING',
  EVICTED = 'EVICTED',
  TERMINATED = 'TERMINATED'
}

export enum ReferralRewardTransaction$Type {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT'
}

export enum RentPaying$AdminPaymentType {
  CANCELED = 'CANCELED',
  INCLUSION = 'INCLUSION',
  BANK_TRANSFER = 'BANK_TRANSFER',
  DEPOSIT = 'DEPOSIT'
}

export enum RentPaying$PayMethod {
  AUTOPAY = 'AUTOPAY',
  MANUAL = 'MANUAL',
  FROM_ORDER = 'FROM_ORDER',
  MARKED_BY_ADMIN = 'MARKED_BY_ADMIN'
}

export enum AdminPayment$Type {
  CANCELED = 'CANCELED',
  INCLUSION = 'INCLUSION',
  BANK_TRANSFER = 'BANK_TRANSFER',
  DEPOSIT = 'DEPOSIT'
}

export enum RentPaying$Status {
  CREATED = 'CREATED',
  ATTEMPTING_AUTOPAY = 'ATTEMPTING_AUTOPAY',
  WAITING_FOR_MANUAL = 'WAITING_FOR_MANUAL',
  PAID = 'PAID'
}

export enum Country {
  SVK = 'SVK',
  CZE = 'CZE',
  POL = 'POL'
}

export enum AppUser$Type {
  ClientUser = 'ClientUser',
  OwnerUser = 'OwnerUser',
  AdminUser = 'AdminUser'
}

export enum Box$BoxSize {
  XS = 'XS',
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE'
}

export enum LockVersion {
  V1 = 'V1',
  V2 = 'V2'
}

export enum ContractCancellationRequest$Reason {
  PotrebujemPriestoryBlizsie = 'PotrebujemPriestoryBlizsie',
  NevyhovujeMiCena = 'NevyhovujeMiCena',
  NevyhovujeMiVelkostPriestoru = 'NevyhovujeMiVelkostPriestoru',
  UzNepotrebujem = 'UzNepotrebujem',
  Ine = 'Ine'
}

export enum Gender {
  Male = 'Male',
  Female = 'Female'
}

export enum Language {
  SK = 'SK',
  CS = 'CS',
  EN = 'EN',
  UK = 'UK',
  PL = 'PL'
}

export enum PromoType {
  Easter = 'Easter',
  Christmas = 'Christmas',
  Snowflake = 'Snowflake',
  Gem = 'Gem',
  Gift = 'Gift',
  Heart = 'Heart',
  Leaf = 'Leaf',
  Sun = 'Sun',
  Umbrella = 'Umbrella'
}

export enum DayOfWeek {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY'
}

export enum IsoEra {
  BCE = 'BCE',
  CE = 'CE'
}

export enum Month {
  JANUARY = 'JANUARY',
  FEBRUARY = 'FEBRUARY',
  MARCH = 'MARCH',
  APRIL = 'APRIL',
  MAY = 'MAY',
  JUNE = 'JUNE',
  JULY = 'JULY',
  AUGUST = 'AUGUST',
  SEPTEMBER = 'SEPTEMBER',
  OCTOBER = 'OCTOBER',
  NOVEMBER = 'NOVEMBER',
  DECEMBER = 'DECEMBER'
}

export enum FranchiseContact$BusinessExperience {
  NO_EXPERIENCE = 'NO_EXPERIENCE',
  ONE_TO_FIVE = 'ONE_TO_FIVE',
  GREATER_THAN_FIVE = 'GREATER_THAN_FIVE'
}

export enum FranchiseContact$CapitalInvestment {
  LESS_THAN_30 = 'LESS_THAN_30',
  BETW_30_AND_40 = 'BETW_30_AND_40',
  BETW_40_AND_50 = 'BETW_40_AND_50',
  GREATER_THAN_50 = 'GREATER_THAN_50'
}

export enum FranchiseContact$PropertyStatus {
  HAS_PROPERTY = 'HAS_PROPERTY',
  WANTS_RENT = 'WANTS_RENT',
  WANTS_BUY_PROPERTY = 'WANTS_BUY_PROPERTY'
}

export enum FranchiseContact$InvestmentReturnPeriod {
  ONE_TO_TWO_YEARS = 'ONE_TO_TWO_YEARS',
  THREE_TO_FIVE_YEARS = 'THREE_TO_FIVE_YEARS',
  SIX_AND_MORE = 'SIX_AND_MORE'
}

export enum InvoicingDetails$Type {
  PravnickaOsoba = 'PravnickaOsoba',
  FyzickaOsoba = 'FyzickaOsoba'
}

export enum SuperAdminJobsRestApi$Job {
  PAYMENT_PROCESSING = 'PAYMENT_PROCESSING',
  CANCEL_CONTRACTS = 'CANCEL_CONTRACTS',
  NOTIFY_RENTS_WITH_PAYMENT_OVERDUE = 'NOTIFY_RENTS_WITH_PAYMENT_OVERDUE',
  NOTIFY_PAYMENTS_TO_BE_ANNOUNCED = 'NOTIFY_PAYMENTS_TO_BE_ANNOUNCED',
  NOTIFY_CONTRACTS_BEFORE_TERMINATION = 'NOTIFY_CONTRACTS_BEFORE_TERMINATION'
}

export enum JobRun$Status {
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
  FAILED = 'FAILED'
}

export enum RefundStatus {
  CREATED = 'CREATED',
  AUTO_SUCCESS = 'AUTO_SUCCESS',
  AUTO_FAIL = 'AUTO_FAIL'
}

export enum Payment$GatewayType {
  STRIPE = 'STRIPE',
  GOPAY = 'GOPAY',
  MOCK = 'MOCK'
}

export enum RentStatus {
  CREATED = 'CREATED',
  INVOICE_SUBMITTED = 'INVOICE_SUBMITTED',
  PAID = 'PAID'
}

export enum Order$Status {
  SUBMITTED = 'SUBMITTED',
  FULFILLED = 'FULFILLED',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED'
}
