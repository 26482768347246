import React, { ComponentType } from 'react'
import { InputComponent, InputComponentProps } from 'src/ui/basic/input/types'
import { FormControl } from 'react-bootstrap'

const strToNum = (str: string): number => {
    const tmp = parseFloat(str)
    return isNaN(tmp) ? 0 : tmp
}

const ensureValidValue = (value: any): void => {
    if (value == null || typeof value !== 'number')
        throw new Error(
            'Expected number value not ' +
                value +
                ' of type type ' +
                typeof value
        )
}

export const NumberInput: InputComponent<number> = ({
    input,
    onBlur,
    onFocus
}) => {
    const { value } = input
    // ensureValidValue(value)

    return (
        <FormControl
            /* Has to be converted to string, because for example if FormControl has typed "0" and user types "5"
             if we just pass number value, displayed will be "05" */
            value={value?.toString()}
            onFocus={onFocus}
            onBlur={onBlur}
            type="number"
            step="any"
            onChange={e => input.onChange(strToNum(e.target.value))}
        />
    )
}

export const DecimalInput: ComponentType<
    InputComponentProps<number> & {
        step?: number
        min?: number
        max?: number
    }
> = ({ input, step, min, max, onBlur, onFocus }) => {
    const { value } = input
    ensureValidValue(value)

    return (
        <FormControl
            /* Has to be converted to string, because for example if FormControl has typed "0" and user types "5"
             if we just pass number value, displayed will be "05" */
            value={value.toString()}
            onFocus={onFocus}
            onBlur={onBlur}
            type="number"
            step={step}
            min={min}
            max={max}
            onChange={e => input.onChange(strToNum(e.target.value))}
        />
    )
}
