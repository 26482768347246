import React, { ComponentType } from 'react'
import { Redirect, Route, Switch } from 'react-router'
import { PageComponent, useStoreValue } from '@ps-aux/react-app-core'
import { AppLayout } from 'src/layout/AppLayout'
import { getAppContext } from 'src/context/AppContext'
import { LoginPage } from 'src/auth/LoginPage'
import { User, UserRole } from 'src/auth/User'
import { usePresentUser } from 'src/auth/useUser'
import { superadminContext } from 'src/superadmin/SuperadminPages'
import { commonPrefix } from 'src/multirole/commonPrefix'
import { DashBoardPages } from 'src/business/Dashboard/DashboardPages'
import {
    maintenancePrefix,
    WarehousePages
} from './business/Warehouse/WarehousePages'

type RouteGuard = (path: string, use: User) => boolean

const MyRoute: ComponentType<{
    comp: ComponentType
    path: string
    guards: RouteGuard[]
    start: string
    // Exact prop MUST be in props here otherwise it won't work with Switch (omg)
    exact: boolean
}> = ({ path, comp: Comp, guards, start, exact }) => {
    const user = usePresentUser()
    return (
        <Route exact={exact} path={path}>
            {r => {
                const location = r.location

                // If the user is maintenance, only allow maintenance pages
                if (user.role === UserRole.Maintenance) {
                    if (!path.includes(maintenancePrefix)) {
                        return <Redirect to={start} />
                    } else {
                        return <Comp />
                    }
                }

                if (path === start) return <Comp />
                for (const g of guards)
                    if (!g(location.pathname, user))
                        return <Redirect to={start} />

                return <Comp />
            }}
        </Route>
    )
}

const guards: RouteGuard[] = [
    // only maintenance allowed in the maintenance context
    (path, user) => {
        if (path.startsWith(maintenancePrefix)) {
            return user.role === UserRole.Maintenance
        }
        return true
    },

    // Only superadmin allowed in the superadmin context
    (path, user) => {
        if (path.startsWith(superadminContext)) return user.superadmin
        return true
    },
    // Owner User allowed only in the common context
    (path, user) => {
        if (user.owner) {
            return path.startsWith(commonPrefix)
        }

        return true
    }
]

export const App: ComponentType<{ pages: PageComponent[] }> = ({ pages }) => {
    const user = useStoreValue(getAppContext().user.store)

    if (!user) return <LoginPage />

    const hasMaintenanceRole = user.role === UserRole.Maintenance

    return (
        <AppLayout>
            <Switch>
                {pages.map(p => (
                    <MyRoute
                        exact={true}
                        key={p.path}
                        start={
                            !hasMaintenanceRole
                                ? DashBoardPages.Dashboard.to()
                                : WarehousePages.ListMaintenance.to()
                        }
                        guards={guards}
                        path={p.path}
                        comp={p.comp}
                    />
                ))}
                <Redirect
                    to={
                        !hasMaintenanceRole
                            ? DashBoardPages.Dashboard.to()
                            : WarehousePages.ListMaintenance.to()
                    }
                />
            </Switch>
        </AppLayout>
    )
}
